import './bootstrap';
import '../css/app.css';

import { createApp, h } from 'vue';
import { createInertiaApp } from '@inertiajs/vue3';
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers';
import { ZiggyVue } from 'ziggy-js';

import { VueReCaptcha, useReCaptcha } from 'vue-recaptcha-v3'
import Notifications from 'notiwind'
import { i18nVue } from 'laravel-vue-i18n'
import PrimeVue from 'primevue/config';
import PrimeVueTailwindStyle from '@/Pages/styles/primevue-tailwind.js';

import { usePassThrough } from "primevue/passthrough";
import Tailwind from "primevue/passthrough/tailwind";


// import * as Sentry from "@sentry/vue";
import { flare } from "@flareapp/js";
import { flareVue } from "@flareapp/vue";

if (process.env.NODE_ENV === 'production') {
    flare.light();
    // flare.test();
}


const CustomTailwind = usePassThrough(
    Tailwind,
    PrimeVueTailwindStyle,
    {
        mergeSections: false,
        mergeProps: true
    }
);




// import Vue from 'vue'

// Vue.use(Vuetify) 

const appName = import.meta.env.VITE_APP_NAME || 'Previsit.ai';
const captchaKey = import.meta.env.VITE_RECAPTCHA_SITE_KEY || '';
export const appear = {
    beforeMount(element) {
        element.style.visibility = 'hidden';
    },
    updated(element, binding, node) {
        if (!binding.value === !binding.oldValue || null === node.transition) {
            return;
        }

        if (!binding.value) {
            node.transition.leave(element, () => {
                element.style.visibility = 'hidden';
            });
            return;
        }

        node.transition.beforeEnter(element);
        element.style.visibility = '';
        node.transition.enter(element);
    }
};

createInertiaApp({
    title: (title) => `${title} - ${appName}`,
    resolve: (name) => resolvePageComponent(`./Pages/${name}.vue`, import.meta.glob('./Pages/**/*.vue')),
    setup({ el, App, props, plugin }) {
        
        const app = createApp({ render: () => h(App, props) })
            .use(PrimeVue,{ unstyled: true, pt: PrimeVueTailwindStyle })
            .use(plugin)            
            .use(ZiggyVue)
            .use(Notifications)
            .use(i18nVue, {
                resolve: async lang => {
                    const langs = import.meta.glob('../../lang/*.json');
                    return await langs[`../../lang/${lang}.json`]();
                }
            })
            .use(VueReCaptcha, { siteKey: captchaKey, autoHideBadge: false })
            .use(flareVue)
            .directive('appear', appear);

        

             // Add Vite asset entry point for images and fonts
        import.meta.glob([
            '../images/**',
            '../fonts/**',
        ]);
            //use in register route

                
            // }
        // }
        // Sentry.init({
        //     app,
        //     dsn: import.meta.env.VITE_SENTRY_VUE_DSN,
        //     environment: import.meta.env.VITE_APP_ENV,
        //     integrations: [
        //       Sentry.browserTracingIntegration(),
        //       Sentry.replayIntegration({
        //         maskAllText: false,
        //         blockAllMedia: false,
        //       }),
        //     ],
        //     // Performance Monitoring
        //     tracesSampleRate: 1.0, //  Capture 100% of the transactions
        //     // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        //     tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
        //     // Session Replay
        //     replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
        //     replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
        //   });        

        
        return app.mount(el);
    },
    progress: {
        color: '#4B5563',
    },
});



